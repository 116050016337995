<template>
  <div>
    <v-card
      v-if="!procesing"
      class="mx-auto mt-5 d-flex align-center flex-column"
    >
      <v-card-title class="pb-b"><h4>Talk to me</h4></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-center align-center flex-column">
        <v-form class="d-flex align-center justify-center flex-column">
          <div v-if="recording">
            <audio ref="player" />
            <av-media
              type="frequ"
              :media="mediaStream"
              line-color="darkorange"
            />
            <div v-if="renderTime > 0">&nbsp;{{ renderTime }}s</div>
            <p v-if="keepTalking">Please, <strong>keep talking</strong></p>
            <p v-else>Now, you can proceed</p>
          </div>
          <v-btn-toggle>
            <v-btn
              color="primary"
              :disabled="recording"
              @click="startRecording"
            >
              <v-icon dense left color="#fff">fa-microphone</v-icon>
              &nbsp;Record
            </v-btn>
            <v-btn
              color="red darken-1"
              :disabled="!recording"
              @click="stopRecording"
            >
              <v-icon dense color="#fff">fa-stop</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-row v-if="!recording && audioUrl != null">
            <v-col class="ma-4">
              <audio :src="audioUrl" controls></audio>
            </v-col>
          </v-row>

          <v-radio-group row v-model="record_lang">
            <v-radio label="English" name="lang" value="en"></v-radio>
            <v-radio label="Portuguese" name="lang" value="pt"></v-radio>
          </v-radio-group>

          <v-btn
            rounded
            color="primary"
            :disabled="audioUrl == null"
            @click="processRecording"
          >
            <v-icon left>fa-keyboard</v-icon>
            Process
          </v-btn>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <router-link :to="{ name: 'upload' }" class="card-footer-item"
          >Or upload a .wav</router-link
        >
      </v-card-actions>
    </v-card>
    <Processing
      v-if="procesing"
      title="Let me transcribe this"
      message="Please, be patient. It won't take long."
    />
  </div>
</template>
<script>
import { mapState } from "vuex"
import Recorder from "recorderjs"
import Processing from "@/components/Processing.vue"

export default {
  name: "Record",
  components: { Processing },
  data() {
    return {
      recording: false,
      recordingStart: 0,
      recordingTime: 0,
      audioUrl: null,
      mediaStream: null,
      mediaStreamSource: null,
      audioContext: null,
      recorder: null,
    }
  },
  computed: {
    keepTalking() {
      return this.renderTime > 0 && this.renderTime < 30
    },
    renderTime() {
      return (Math.round(this.recordingTime / 100) / 10).toFixed(1)
    },
    record_lang: {
      get() {
        return this.$store.state.speechgraph.lang
      },
      set(value) {
        this.$store.commit("speechgraph/changeLang", value)
      },
    },
    ...mapState({
      procesing: state => state.speechgraph.procesing,
      audio: state => state.speechgraph.audio,
    }),
  },
  methods: {
    startRecording() {
      if (!this.recording) {
        this.startMicrophone()
        this.recording = true
      }
    },
    startMicrophone() {
      this.audioUrl = null
      this.audioContext = new AudioContext()
      this.$store.commit("speechgraph/audio", null)

      const success = stream => {
        console.log("started recording")
        this.mediaStream = stream
        this.$refs.player.srcObject = stream
        this.mediaStreamSource =
          this.audioContext.createMediaStreamSource(stream)
        this.recorder = new Recorder(this.mediaStreamSource, {
          workerPath: "/js",
        })
        this.recorder.record()
        this.recordingStart = new Date().getTime()
        this.recordingTime = 0
        this.recordingInterval = setInterval(() => {
          this.recordingTime = new Date().getTime() - this.recordingStart
        }, 100)
      }

      const fail = e => {
        console.error("recording failure", e)
      }

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: false,
            audio: true,
          })
          .then(success)
          .catch(fail)
      } else {
        navigator.getUserMedia(
          {
            video: false,
            audio: true,
          },
          success,
          fail
        )
      }
    },
    stopRecording() {
      if (this.recording) {
        clearInterval(this.recordingInterval)
        this.recording = false
        this.stopMicrophone()
      }
    },
    stopMicrophone() {
      this.recorder.stop()
      const vm = this
      this.recorder.exportWAV(function (blob) {
        var url = URL.createObjectURL(blob)
        vm.audioUrl = url
        vm.$store.commit("speechgraph/audio", blob)
        console.log("recorder", url)
      })
      if (this.mediaStream) {
        this.mediaStream.getTracks()[0].stop()
      }
      if (this.mediaStreamSource) {
        this.mediaStreamSource.disconnect()
      }
      if (this.audioContext) {
        this.audioContext.close()
      }
      if (this.mediaRecorder) {
        this.mediaRecorder.stop()
        this.audioUrl = null
        this.$store.commit("speechgraph/audio", null)
      }
    },
    processRecording() {
      this.$store.dispatch("speechgraph/processRecording")
    },
  },
}
</script>

<template>
  <v-card width="90%" class="mx-auto mt-5 d-flex align-center flex-column">
    <v-card-title class="pb-b"
      ><h4>{{ title }}</h4></v-card-title
    >
    <v-divider></v-divider>
    <v-card-text class="d-flex justify-center align-center flex-column">
      <p><i :class="iconClass"></i></p>
      <p>{{ message }}</p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Processing",
  props: {
    title: { type: String, required: true },
    message: { type: String, required: true },
    icon: { type: String, default: "fa-compact-disc" },
  },
  computed: {
    iconClass() {
      return "fas fa-spin fa-2x " + this.icon
    },
  },
}
</script>
